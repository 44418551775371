import 'regenerator-runtime/runtime';
import 'style/style';

//react
import { App } from 'components';
import { createElement } from 'react';
import { render } from 'react-dom';

const container = document.querySelector('.app');
const app = createElement(App);
render(app, container);

//see development.js
module.hot && module.hot.accept('components', Boolean);
module.hot && module.hot.accept('style/style', Boolean);
